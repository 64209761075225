import React from 'react';
import { Tab } from 'react-bootstrap';
import './Simulator.scss';
import { useTranslation } from 'react-i18next';
import SimulatorSearch from '../SimulatorSearch/SimulatorSearch';

const Simulator = () => {
    const { t } = useTranslation();
    return (
        <Tab.Pane eventKey="simulator">
            <div className="title">{t('simulator.title')}</div>
            <SimulatorSearch suggestionsLimit={5} />
        </Tab.Pane>
    );
};
export default Simulator;
