import React, {useContext, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
import {SearchBox, Suggestions} from '@mappr/react-lib';
import Downshift from 'downshift';
import './Search.scss';
import {AppContext} from '../../context';
import PropTypes from 'prop-types';

const Search = ({geocoding, openedControl}) => {
    const [inputValue, setInputValue] = useState('');
    const [initialInputValue, setInitialInputValue] = useState('');
    const {projectConfigs} = useContext(AppContext);

    const handleSearchInput = (inputValue, onChange, stateAndHelpers) => {
        if (stateAndHelpers.selectedItem) {
            return;
        }
        setInputValue(inputValue);
        setInitialInputValue(inputValue);

        let str = inputValue.split(' ').filter((s) => s.length >= 2);

        setTimeout(() => {
            onChange(str.length ? str.join(' ') : '');
        }, 500);
    };

    const handleSuggestionSelect = (
        token,
        onSelect,
        isGeocoder,
        stateAndHelpers
    ) => {
        stateAndHelpers.clearSelection();
        setInputValue('');
        setInitialInputValue('');
        onSelect(token, isGeocoder);
    };

    const stateReducer = (state, changes) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.mouseUp:
            case Downshift.stateChangeTypes.keyDownArrowDown:
            case Downshift.stateChangeTypes.keyDownArrowUp: {
                return {
                    ...changes,
                    inputValue: '',
                };
            }
            default:
                return changes;
        }
    };

    const getFormattedText = (text) => {
        const words = initialInputValue.trim().split(' ');
        let lastWord = words[words.length - 1].toLowerCase().trim();
        lastWord = lastWord.replaceAll('\\', '');
        const regexp = new RegExp(`(.*)?(${lastWord})(.*)?`, 'ig');
        return text.replace(regexp, '$1<strong>$2</strong>$3');
    };

    return (
        <SearchBox geocoding={geocoding}>
            {/* eslint-disable-next-line no-empty-pattern */}
            {({}, {onUpdate}) => (
                <Suggestions limit={5}>
                    {({suggestions}, {onSelect}) => {
                        const allSuggestions = [...suggestions];
                        return (
                            <Downshift
                                inputValue={inputValue}
                                onChange={(selection, stateAndHelpers) =>
                                    selection &&
                                    handleSuggestionSelect(
                                        selection.guid,
                                        onSelect,
                                        selection.isGeocoder,
                                        stateAndHelpers
                                    )
                                }
                                onInputValueChange={(
                                    inputValue,
                                    stateAndHelpers
                                ) =>
                                    handleSearchInput(
                                        inputValue,
                                        onUpdate,
                                        stateAndHelpers
                                    )
                                }
                                itemToString={(item) =>
                                    item ? item.token : ''
                                }
                                stateReducer={(state, changes) =>
                                    stateReducer(
                                        state,
                                        changes,
                                        allSuggestions,
                                        onSelect
                                    )
                                }
                            >
                                {({
                                      getInputProps,
                                      getItemProps,
                                      getMenuProps,
                                      inputValue,
                                      highlightedIndex,
                                      isOpen,
                                  }) => (
                                    <div
                                        className={` search-wrapper form-group form-group-custom-autocomplete position-relative z-index-1 ${
                                            inputValue.length >= 2 &&
                                            suggestions.length
                                                ? 'expanded'
                                                : ''
                                        }`}
                                    >

                                        <label className="search-label">
                                        <input
                                            className={`search
                                              ${
                                                openedControl === 'calendar'
                                                    ? 'disabled'
                                                    : ''
                                            }
                                            ${
                                                isOpen &&
                                                suggestions.length &&
                                                inputValue.length >= 2
                                                    ? 'open'
                                                    : 'hide'
                                            }`}
                                            placeholder="Rechercher une gare"
                                            {...getInputProps({
                                                disabled:
                                                    openedControl ===
                                                    'calendar',
                                            })}
                                        />
                                        </label>
                                        {inputValue.length >= 2 &&
                                        suggestions.length ? (
                                            <>
                                                <ul
                                                    className={
                                                        'autocomplete list-unstyled'
                                                    }
                                                    {...getMenuProps()}
                                                >
                                                    {suggestions.map(
                                                        (suggestion, index) => {
                                                            return (
                                                                <li
                                                                    className="pb-2"
                                                                    key={
                                                                        suggestion.guid
                                                                    }
                                                                    {...getItemProps(
                                                                        {
                                                                            key: suggestion.guid,
                                                                            index,
                                                                            item: suggestion,
                                                                            style: {
                                                                                backgroundColor:
                                                                                    highlightedIndex ===
                                                                                    index
                                                                                        ? 'var(--bs-gray-300)'
                                                                                        : 'transparent',
                                                                            },
                                                                        }
                                                                    )}
                                                                >
                                                                    {ReactHtmlParser(
                                                                        getFormattedText(
                                                                            suggestion
                                                                                .properties[
                                                                                `${projectConfigs?.poiTitleField}`
                                                                                ]
                                                                        )
                                                                    )}
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </>
                                        ) : null}
                                    </div>
                                )}
                            </Downshift>
                        );
                    }}
                </Suggestions>
            )}
        </SearchBox>
    );
};
Search.propTypes = {
    geocoding: PropTypes.bool,
    openedControl: PropTypes.string,
};
export default Search;
