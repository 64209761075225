import React, { useCallback, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

export const JourneyPopUp = ({ primaryColor }) => {
    useEffect(() => {
        const axeptop = document.getElementById('axeptio_overlay');
        if (axeptop) {
            document.getElementById('axeptio_overlay').style.zIndex = '1';
        }
    });
    const { t } = useTranslation();
    const history = useHistory();
    const { mode } = useParams();

    const goToPage = useCallback(() => {
        history.push({
            pathname: `/fr/${mode}/list`,
            hash: window.location.hash,
            state: { prevPath: history.location.pathname },
        });
    }, [history, mode]);
    return (
        <Container
            fluid
            id="MapPopup"
            className="popup max-z-index  pt-3 pb-3 position-absolute "
            style={{ bottom: '0', width: '100vw', background: primaryColor }}
        >
            <Row>
                <Col>
                    <Button
                        className="nav-link w-100 cursor-pointer btn-calculate active"
                        onClick={goToPage}
                    >
                        {t('goToDetails')}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};
JourneyPopUp.propTypes = {
    primaryColor: PropTypes.string,
};
