import React, { useCallback } from 'react';
import './Poi.scss';
import Footer from '../../Footer/Footer';
import SidebarToggleButton from '../../../../components/SidebarToggleButton/SidebarToggleButton';
import PropTypes from 'prop-types';
import Header from '../../Header/Header';
import { POIList } from '../../../../components/LineView/POIList/POIList';

const LineView = ({
    project,
    item,
    onToggle,
    toggleMenu,
    primaryColor,
    isSidebarOpen,
    selectItem,
    line,
    loading,
}) => {
    const handleToggle = useCallback(() => {
        onToggle('lines', line);
    }, [onToggle, line]);

    return (
        <>
            {isSidebarOpen ? (
                <>
                    <Header
                        projectConfigs={project.configs}
                        deselect={handleToggle}
                    />
                    <POIList
                        project={project}
                        handleToggle={handleToggle}
                        item={item}
                        line={line}
                        selectItem={selectItem}
                        loading={loading}
                    />
                    <Footer
                        toggleMenu={toggleMenu}
                        primaryColor={primaryColor}
                    />
                </>
            ) : (
                <SidebarToggleButton
                    toggleMenu={toggleMenu}
                    isSidebarOpen={isSidebarOpen}
                />
            )}
        </>
    );
};
LineView.propTypes = {
    item: PropTypes.array,
    project: PropTypes.object,
    onToggle: PropTypes.func,
    toggleMenu: PropTypes.func,
    primaryColor: PropTypes.string,
    isSidebarOpen: PropTypes.bool,
    selectItem: PropTypes.func,
    line: PropTypes.string,
    loading: PropTypes.bool,
};
export default LineView;
