import React, {useEffect} from 'react';
import './Loader.scss';
import {getHash} from '../../../utils/urlUtils';

const ListLoader = () => {
    const urlColorMode = getHash('color') && `#${getHash('color')}`;

    useEffect(() => {
        const root = document.documentElement;
        root?.style.setProperty(
            '--background-color', urlColorMode
        );
    }, [urlColorMode]);


    return (
        <div className="loading-1">
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
        </div>
    );
};

ListLoader.propTypes = {};
ListLoader.defaultProps = {};

export default ListLoader;
