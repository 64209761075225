export const reducer = (state, update) => {
    switch (update.prop) {
        case 'baseMap':
            return {
                ...state,
                baseMap: update.value,
            };
        case 'locale':
            return {
                ...state,
                locale: {
                    ...state.locale,
                    value: update.value,
                },
            };
        case 'mobilePage':
            return {
                ...state,
                mobilePage: update.value,
            };
        default:
            return {
                ...state,
                [update.prop]: update.value,
            };
    }
};
