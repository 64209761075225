import React from 'react';
import './SidebarToggleButton.scss';
import PropTypes from 'prop-types';

const SidebarToggleButton = ({ toggleMenu, open }) => {
    return (
        <span
            id="collapse-sidebar-toggle"
            className={`rightBar sidebar__toggle position-absolute cursor-pointer ${
                open ? '' : 'expanded'
            } ${open === 'left' ? 'left-sidebar' : 'right-sidebar'}`}
            onClick={toggleMenu}
            aria-controls="collapse-filters"
            aria-expanded={open}
            style={{ height: '100vh' }}
        />
    );
};
SidebarToggleButton.propTypes = {
    toggleMenu: PropTypes.func,
    open: PropTypes.bool,
};
export default SidebarToggleButton;
