import React, { useEffect, useState } from 'react';
import Header from '../../Header/Header';
import { NavitiaSearchBox } from '@mappr/react-lib';
import ListLoader from '../../../../components/loaders/ListLoader/ListLoader';
import { Journey } from '../../../../components/Journey/Journey';
import PropTypes from 'prop-types';
import NotFound from '../../../../components/NotFound/NotFound';

export const JourneyList = ({ project }) => {
    const [showComponent, setShowComponent] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShowComponent(true);
        }, 3000);
    }, []);
    return (
      <>
          <NavitiaSearchBox addressLimit={5} stopAreaLimit={3}>
              {({ journeys, loading, error }, { onRemove }) => {
                  if (loading) {
                      return <ListLoader />;
                  }
                  if (error) {
                      return <NotFound />;
                  }

                  return (
                    <>
                        <Header
                          projectConfigs={project.configs}
                          deselect={() => {
                              onRemove();
                          }}
                        />

                        {showComponent ? (
                          <Journey journeys={journeys} />
                        ) : (
                          <ListLoader />
                        )}
                    </>
                  );
              }}
          </NavitiaSearchBox>
      </>
    );
};
JourneyList.propTypes = {
    project: PropTypes.object,
};
