import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import close from '../../../assets/icons/close.png';
import PropTypes from 'prop-types';

const LegalInformation = ({ primaryColor }) => {
    const { t } = useTranslation();
    const [isOpenLegalInformation, setIsOpenLegalInformation] = useState(false);
    return (
        <footer className="legal-info">
            <span
                style={{ cursor: 'pointer', float: 'center' }}
                onClick={() =>
                    setIsOpenLegalInformation(!isOpenLegalInformation)
                }
            >
                {t('legalInformation.title')}
            </span>
            {isOpenLegalInformation && (
                <div
                    className="list"
                    style={{ border: `1px solid ${primaryColor}` }}
                >
                    <span
                        className="close"
                        onClick={() => setIsOpenLegalInformation(false)}
                    >
                        <img src={close} width={24} alt="" />
                    </span>
                    <ul className="p-0">
                        <li>
                            <a href="#" style={{ color: primaryColor }}>
                                Description textuelle
                            </a>
                        </li>
                        <li>
                            <a href="#" style={{ color: primaryColor }}>
                                Aide
                            </a>
                        </li>
                        <li>
                            <a href="#" style={{ color: primaryColor }}>
                                Open data
                            </a>
                        </li>
                        <li>
                            <a href="#" style={{ color: primaryColor }}>
                                Cookies
                            </a>
                        </li>
                        <li>
                            <a href="#" style={{ color: primaryColor }}>
                                Vectuel
                            </a>
                        </li>
                        <li>
                            <a href="#" style={{ color: primaryColor }}>
                                Mentions légales
                            </a>
                        </li>
                    </ul>
                    <div>{t('copyright')}</div>
                </div>
            )}
        </footer>
    );
};

LegalInformation.propTypes = {
    primaryColor: PropTypes.string,
};
export default LegalInformation;
