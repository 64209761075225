import React from 'react';
import PropTypes from 'prop-types';
import './SecondaryLayerPopUp.scss';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {getHash} from '../../../utils/urlUtils';

function SecondaryLayerPopUp(props) {
    const {properties, projectConfigs, onClose} = props;
    const urlColorMode = getHash('color') && `#${getHash('color')}`;
    return (
        <Container
            id="MapPopup"
            className="MapPopup secondary-layer-popup bg-white rounded"
        >
            {properties.nom && (
                <Row>
                    <Col
                        className={'mb-2'}
                        style={{color: urlColorMode || projectConfigs.primaryColor}}
                    >
                        {properties.nom}
                    </Col>
                </Row>
            )}

            <Row>
                <Col>{properties.description || ''}</Col>
            </Row>
            {properties.url && (
                <Row className="justify-content-md-center secondary-button">
                    <Col md={6}>
                        <a
                            href={properties.url}
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            <Button
                                variant={'light'}
                                style={{
                                    border: `1px solid ${projectConfigs.primaryColor}`,
                                }}
                                className=" w-100  cursor-pointer mt-4 "
                            >
                                En savoir plus
                            </Button>
                        </a>
                    </Col>
                </Row>
            )}
            <div className="secondary-close" onClick={onClose}/>
        </Container>
    );
}

SecondaryLayerPopUp.propTypes = {
    properties: PropTypes.object,
    locale: PropTypes.string,
    projectConfigs: PropTypes.object,
    onClose: PropTypes.func,
};

export default SecondaryLayerPopUp;
