import React from 'react';
import './OldBrowserMessage.scss';
import logo from '../../../src/assets/img/mappr_logo_300.png';
import chrome from '../../assets/img/browsers/chrome.png';
import firefox from '../../assets/img/browsers/firefox.png';
import edge from '../../assets/img/browsers/edge.png';
import { useTranslation } from 'react-i18next';

function OldBrowserMessage() {
    const { t } = useTranslation();
    return (
        <div className="OldBrowserMessage">
            <div className="message-container">
                <div className="logo-container">
                    <img src={logo} alt="Mappr logo" />
                </div>
                <div className="footer">
                    <p>{t('oldBrowserMessage')}</p>
                    <div>
                        <a
                            href={'https://www.google.com/chrome'}
                            className={'icon'}
                        >
                            <img src={chrome} alt="Google Chrome" />
                        </a>
                        <a
                            href={'https://www.mozilla.org/en-US/firefox/new/'}
                            className={'icon'}
                        >
                            <img src={firefox} alt="Mozilla Firefox" />
                        </a>
                        <a
                            href={
                                'https://www.microsoft.com/en-us/windows/microsoft-edge'
                            }
                            className={'icon'}
                        >
                            <img src={edge} alt="Microsoft Edge" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OldBrowserMessage;
