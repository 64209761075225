import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { NavitiaSearchBox } from '@mappr/react-lib';
import ListLoader from '../../../../components/loaders/ListLoader/ListLoader';
import { Journey } from '../../../../components/Journey/Journey';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../../context';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import NotFound from '../../../../components/NotFound/NotFound';

export const JourneyList = ({ project }) => {
    const { t } = useTranslation();
    const [showComponent, setShowComponent] = useState(false);
    const { setAppState } = useContext(AppContext);
    const { page } = useParams();
    const history = useHistory();
    useEffect(() => {
        setTimeout(() => {
            setShowComponent(true);
        }, 3000);
    }, []);

    const goToMap = useCallback(() => {
        if (page) {
            setAppState({ prop: 'mobilePage', value: 'map' });
            history.push({
                pathname: '/fr/line/map/',
                hash: window.location.hash,
            });
        }
    }, [history, page, setAppState]);
    return (
        <main>
            <Container className="sidebar-page back-btn pt-0">
                <NavitiaSearchBox addressLimit={5} stopAreaLimit={3}>
                    {/* eslint-disable-next-line no-empty-pattern */}
                    {({ journeys, error, loading }, { onRemove }) => {
                        if (loading) {
                            return <ListLoader />;
                        }
                        if (error) {
                            return <NotFound />;
                        }
                        return (
                            <>
                                {showComponent ? (
                                    <>
                                        <Row className="flex-row align-items-center back-btn cursor-pointer">
                                            <Col onClick={onRemove}>
                                                <i
                                                    style={{
                                                        borderColor:
                                                            project.secondaryColor,
                                                    }}
                                                    className="arrow left"
                                                />
                                                <span
                                                    style={{
                                                        color: project.secondaryColor,
                                                    }}
                                                    className="ps-2"
                                                >
                                                    {t('back')}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Journey journeys={journeys} />
                                        </Row>
                                        <Container>
                                            <Row>
                                                <Col className={'ps-0 pe-0'}>
                                                    <Button
                                                        className="nav-link w-100 cursor-pointer mb-3 btn-calculate active"
                                                        onClick={goToMap}
                                                    >
                                                        {t('goMap')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </>
                                ) : (
                                    <ListLoader />
                                )}
                            </>
                        );
                    }}
                </NavitiaSearchBox>
            </Container>
        </main>
    );
};
JourneyList.propTypes = {
    project: PropTypes.object,
};
