import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SCREEN_SIZES } from '../config/constants';

const getMatchingModeForScreenWidth = () => {
    let matchingKey;
    const width = window.innerWidth;
    for (let [key, value] of Object.entries(SCREEN_SIZES)) {
        if (width <= value[0]) {
            break;
        }
        matchingKey = key;
    }
    return matchingKey;
};

function Layouts({ autoTrack = true, currentMode, children }) {
    const [mode, setMode] = useState(getMatchingModeForScreenWidth());

    const autoUpdateMode = useCallback(() => {
        const currentMode = getMatchingModeForScreenWidth();
        if (currentMode !== mode) {
            setMode(currentMode);
        }
    }, [mode]);

    const removeWindowResizeListener = useCallback(() => {
        window.removeEventListener('resize', autoUpdateMode);
    }, [autoUpdateMode]);

    const attachWindowResizeListener = useCallback(() => {
        removeWindowResizeListener();
        window.addEventListener('resize', autoUpdateMode);
    }, [autoUpdateMode, removeWindowResizeListener]);

    useEffect(() => {
        if (autoTrack) {
            autoUpdateMode();
            attachWindowResizeListener();
        }
        return () => {
            removeWindowResizeListener();
        };
    }, [
        attachWindowResizeListener,
        autoTrack,
        autoUpdateMode,
        removeWindowResizeListener,
    ]);

    useEffect(() => {
        if (currentMode) {
            setMode(currentMode);
        }
    }, [currentMode]);

    useEffect(() => {
        if (autoTrack) {
            attachWindowResizeListener();
        } else {
            removeWindowResizeListener();
        }

        return () => {
            removeWindowResizeListener();
        };
    }, [
        attachWindowResizeListener,
        autoTrack,
        mode,
        removeWindowResizeListener,
    ]);

    return (
        <div data-mode={mode} className="h-100 w-100">
            {children(mode)}
        </div>
    );
}

Layouts.propTypes = {
    currentMode: PropTypes.string,
    autoTrack: PropTypes.bool,
    screenSizes: PropTypes.object,
    children: PropTypes.func,
};

export default Layouts;
