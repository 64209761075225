import React, {useCallback, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {NavitiaSearchBox, NavitiaSuggestions} from '@mappr/react-lib';
import {Button, Col, Container, Row} from 'react-bootstrap';
import AutocompieteInput from './AutocompleteInput';
import {useParams} from 'react-router';
import './SimulatorSearch.scss';
import {getHash} from '../../utils/urlUtils';
import {AppContext} from '../../context';
import CssFilterConverter from 'css-filter-converter';

const SimulatorSearch = ({from}) => {
    const {t} = useTranslation();
    const {id} = useParams();
    const [fromItem, setFormItem] = useState(from || {});
    const [toItem, setToItem] = useState({});
    const {projectConfigs} = useContext(AppContext);
    const urlColorMode = getHash('color') && `#${getHash('color')}`;


    useEffect(() => {
        if (projectConfigs) {
            const {primaryColor} = projectConfigs;
            const root = document.documentElement;
            const result = CssFilterConverter.hexToFilter(urlColorMode || primaryColor);
            root?.style.setProperty(
                '--primary-color', urlColorMode || primaryColor
            );
            root?.style.setProperty(
                '--filter-svg-color', result?.color
            );
        }
    }, [projectConfigs, urlColorMode]);


    useEffect(() => {
        if (id) {
            setFormItem({...fromItem, name: id});
        }
    }, [fromItem, id]);

    const handleSwitchButtonClick = useCallback(() => {
        const from = fromItem;
        setFormItem(toItem);
        setToItem(from);
    }, [fromItem, toItem]);

    const handleSearchResult = useCallback(
        (onSearch) => {
            let from = `${fromItem.coord.lon};${fromItem.coord.lat}`;
            let to = `${toItem.coord.lon};${toItem.coord.lat}`;
            if (fromItem.embedded_type === 'stop_area') {
                from = fromItem.id;
            }
            if (toItem.embedded_type === 'stop_area') {
                to = toItem.id;
            }
            onSearch(from, to);
        },
        [fromItem, toItem]
    );

    return (
        <NavitiaSearchBox addressLimit={5} stopAreaLimit={3}>
            {/* eslint-disable-next-line no-empty-pattern */}
            {({}, {onSearch, onUpdate}) => (
                <NavitiaSuggestions>
                    {({suggestions, loading}, {onSelect}) => {
                        return (
                            <Container className={'simulator'}>
                                <Row>
                                    <Col
                                        md={10}
                                        sm={12}
                                        xs={12}
                                        className="p-0 "
                                    >
                                        <AutocompieteInput
                                            onUpdate={onUpdate}
                                            onSelect={onSelect}
                                            suggestions={suggestions}
                                            setItem={setFormItem}
                                            item={fromItem}
                                            label={t('simulator.fromLabel')}
                                            point={'start'}
                                            placeholder={t(
                                                'simulator.fromPlaceholder'
                                            )}
                                            loading={loading}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col
                                        className={'switch-button mt-3'}
                                        md={{span: 4, offset: 8}}
                                    >
                                        <span
                                            onClick={handleSwitchButtonClick}
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    className="position-relative"
                                    style={{marginTop: '-5px'}}
                                >
                                    <Col
                                        md={10}
                                        sm={12}
                                        xs={12}
                                        className="p-0"
                                    >
                                        <AutocompieteInput
                                            onUpdate={onUpdate}
                                            onSelect={onSelect}
                                            suggestions={suggestions}
                                            setItem={setToItem}
                                            item={toItem}
                                            label={t('simulator.toLabel')}
                                            point={'end'}
                                            placeholder={t(
                                                'simulator.toPlaceholder'
                                            )}
                                            loading={loading}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Button
                                        className={`nav-link w-100 mb-3 btn-calculate cursor-pointer  ${
                                            fromItem &&
                                            Object.keys(fromItem).length &&
                                            toItem &&
                                            Object.keys(toItem).length
                                                ? ''
                                                : 'disabled'
                                        }`}
                                        onClick={() => {
                                            handleSearchResult(onSearch);
                                        }}
                                    >
                                        {t('simulator.calculate')}
                                    </Button>
                                </Row>
                            </Container>
                        );
                    }}
                </NavitiaSuggestions>
            )}
        </NavitiaSearchBox>
    );
};

SimulatorSearch.propTypes = {
    from: PropTypes.object,
};
export default SimulatorSearch;
