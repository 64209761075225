import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Modal } from 'react-bootstrap';
import AppInfoPage from './AppInfoPage/AppInfoPage';
import './AppInfoModal.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import close from '../../../assets/icons/close.png';

function AppInfoModal({ project, open, appInfoModal, onClose }) {
    const { domain } = project.configs;
    const { t } = useTranslation();
    const { lang } = useParams();
    const [page, setPage] = useState(0);
    const _appInfoModal = appInfoModal[lang];
    function resetState() {
        setPage(0);
    }

    return (
      <Modal
        id="modal-app-info"
        className="AppInfoModal"
        show={open}
        centered={true}
        scrollable={true}
        onHide={onClose}
        onExited={resetState}
      >
          <Modal.Header className={'p-0'}>
              <Modal.Title />

              <Image
                className="cursor-pointer"
                onClick={onClose}
                src={close}
                alt=""
              />
          </Modal.Header>
          <Modal.Body>
              {_appInfoModal &&
                _appInfoModal.map((info, index) => (
                  <AppInfoPage
                    key={`page-${index}`}
                    shown={page === index}
                    title={info.title}
                    //  image={`${mprApiUrl}/${domain}/app-info/${info.image}`}
                    image={`https://cloud.squidex.io/api/assets/${domain}/${info.image}`} //TODO: change to api url when image is saved during import
                    description={info.description}
                    project={project}
                  />
                ))}
          </Modal.Body>
          <Modal.Footer className="justify-content-center flex-column border-0 pt-0">
              <Button className="mb-2 btn-go-map" variant='secondary' onClick={onClose}>
                  {t('appInfo.buttons.goToMap')}
              </Button>
          </Modal.Footer>
      </Modal>
    );
}

AppInfoModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    project: PropTypes.object,
    appInfoModal: PropTypes.object,
};
AppInfoModal.defaultProps = {
    open: false,
};

export default AppInfoModal;
