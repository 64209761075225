import React, { useCallback } from 'react';
import { SelectedItem } from '@mappr/react-lib';
import { POIPage } from '../../../../components/LineView/POIPage/POIPage';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MapContext } from '../../../../context';

const Poi = ({ project }) => {
    const { t } = useTranslation();
    const handleDeselect = useCallback((onDeselect, setMapItem) => {
        onDeselect();
        setMapItem && setMapItem('selected', null, {});
    }, []);
    return (
        <>
            <SelectedItem>
                {({ error, item }, { deselect }) => {
                    if (!item) {
                        return null;
                    }
                    if (error) {
                        return (
                            <div className="notification is-danger">
                                {error}
                            </div>
                        );
                    }
                    return (
                        <>
                            <MapContext.Consumer>
                                {({ feature: { setMapItem } }) => {
                                    return (
                                        <Container className="mb-4 flex-row align-items-center back-btn cursor-pointer">
                                            <Row>
                                                <Col
                                                    onClick={() =>
                                                        handleDeselect(
                                                            deselect,
                                                            setMapItem
                                                        )
                                                    }
                                                >
                                                    <i
                                                        style={{
                                                            borderColor:
                                                                project.secondaryColor,
                                                        }}
                                                        className="arrow left"
                                                    />
                                                    <span
                                                        style={{
                                                            color: project.secondaryColor,
                                                        }}
                                                        className="ps-2"
                                                    >
                                                        {t('back')}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Container>
                                    );
                                }}
                            </MapContext.Consumer>
                            <POIPage project={project} item={item} />
                        </>
                    );
                }}
            </SelectedItem>
        </>
    );
};
Poi.propTypes = {
    project: PropTypes.object,
};
export default Poi;
