import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import './AppInfoPage.scss';

function AppInfoPage(props) {
  const { image, description } = props;

  return (
    <div className={`AppInfoPage fade ${props.shown ? 'show' : 'd-none'}`}>
      <div className={'text-center'}>
        {image && <img className={'app-logo'} src={image} alt="Logo" />}
      </div>
      <div className="text-normal" style={{ color: '#363636' }}>
        {ReactHtmlParser(description)}
      </div>
    </div>
  );
}

AppInfoPage.propTypes = {
  shown: PropTypes.bool,
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  project: PropTypes.object,
};
AppInfoPage.defaultProps = {
  shown: false,
};

export default AppInfoPage;
