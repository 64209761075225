import common from './common.json';
import poi from './poi.json';
import results from './results.json';

const namespaces = {
    common,
    poi,
    results,
};

export default namespaces;
