import React from 'react';
import { Container, Image, Row } from 'react-bootstrap';
import './Tutorial.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import arrowSecondaryLayer from '../../../assets/icons/arrow-1.svg';
import calendarArrow from '../../../assets/icons/arrow-2.svg';

const Tutorial = (props) => {
    const { t } = useTranslation();
    return (
        <Container
            fluid
            className="mappr-tutorial"
            onClick={() => props.handleOpenTutorial(false)}
        >
            <div className="tutorial">
                <Row>
                    <div className={'d-flex'}>
                        <div className="text text-secondary-layer me-4">
                            <div className="layer-arrow float-left mt-3 mb-2">
                                <Image src={arrowSecondaryLayer} width={16} />
                            </div>
                            <p className="text-center mb-0 d-inline-block ml-2">
                                {t('tutorial.secondaryLayer')}
                            </p>
                        </div>
                        <div className="text text-calendar ms-4">
                            <div className="layer-arrow float-left mt-3 mb-2">
                                <Image src={calendarArrow} width={30} />
                            </div>
                            <p className="text-center mb-0 d-inline-block ml-2">
                                {t('tutorial.calendar')}
                            </p>
                        </div>
                    </div>
                </Row>
            </div>
        </Container>
    );
};

Tutorial.propTypes = {
    handleOpenTutorial: PropTypes.func.isRequired,
};
export default Tutorial;
